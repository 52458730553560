import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../styles/footer.scss"

const Footer = ({ siteTitle }) => (
  <footer>
   <div className="footer-outer">
        {/*<nav>
          <Link to="/introduction">Introduction</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/get-involved">Get Involved</Link>
          <Link to="/learn-more">Learn more</Link>
        </nav>*/}
      <div className="footer-inner">
        <span>
        &copy;2021 Longtermism.com
        </span>
        <Link to='/about'>About this site</Link>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
